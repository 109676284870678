import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import Container from 'components/ui/Container';
// import TitleSection from 'components/ui/TitleSection';

import * as Styled from './styles';

import { Avatar, Button } from 'antd';

import { SiGooglescholar, SiLinkedin, SiDblp, SiResearchgate, SiPublons, SiOrcid, SiElsevier, SiTwitter, SiGithub, SiGmail } from "react-icons/si";

const Social = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "social section" } }) {
        frontmatter {
          googlescholar
          linkedin
          twitter
          researchgate
          dblp
          github
          publons
          orcid
          scopus
          email
        }
      }
    }
  `);

  const socialLinks = markdownRemark.frontmatter;

  return (
    <Styled.Social>
      <Styled.SocialButtons>
        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiGmail style={{position: 'relative', right: -7, top: 6}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.email}>Email</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiGooglescholar style={{position: 'relative', right: -7, top: 6}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.googlescholar}>Google Scholar</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiLinkedin style={{position: 'relative', right: -7, top: 6}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.linkedin}>Linkedin</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiDblp style={{position: 'relative', right: -7, top: 5}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.dblp}>DBLP</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiResearchgate style={{position: 'relative', right: -6, top: 5}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.researchgate}>ResearchGate</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiPublons style={{position: 'relative', right: -6, top: 5}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.publons}>Publons</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiOrcid style={{position: 'relative', right: -6, top: 5}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.orcid}>ORCiD</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiElsevier style={{position: 'relative', right: -6, top: 5}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.scopus}>Scopus</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiTwitter style={{position: 'relative', right: -6, top: 5}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.twitter}>Twitter</Button>
        </Styled.SocialItem>

        <Styled.SocialItem>
          <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
                  icon={<SiGithub style={{position: 'relative', right: -6, top: 5}}/>}
          />
          <Button style={{ color: '#b91c1c'}} type="link" href={socialLinks.github}>GitHub</Button>
        </Styled.SocialItem>
    </Styled.SocialButtons>
    </Styled.Social>
  )
};

export default Social;
