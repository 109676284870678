import React from 'react';

import Container from 'components/ui/Container';

import * as Styled from './styles';
import Button from '../ui/Button';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { Tag, Divider } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

const Footer = () => {
  const query = useStaticQuery(graphql`
    query {
      currentBuildDate {
        currentDate
      }
    }
  `);

  return (
    <Styled.Footer>
      <Container>
        <Styled.Links>
          © Vahid Alizadeh 2024 | Designed by GatsbyJs, React, and TailwindCSS.
          {/*<Styled.Link*/}
          {/*  href="http://www.v-alizadeh.ir/"*/}
          {/*  rel="noreferrer noopener"*/}
          {/*  target="_blank">*/}
          {/*  My MSc Website*/}
          {/*</Styled.Link>*/}
        </Styled.Links>

        <Divider orientation="center">
          Last update: <span> </span>
          <Tag icon={<SyncOutlined spin />} color="processing">
            {query.currentBuildDate.currentDate}
          </Tag>
        </Divider>

        <Styled.Links>
          <Link to="https://phd.v-alizadeh.info/">
            <Button primary>My PhD Website</Button>
          </Link>

          <Link to="https://msc.v-alizadeh.info/">
            <Button primary>My MSc Website</Button>
          </Link>
        </Styled.Links>
      </Container>
    </Styled.Footer>
  );
};

export default Footer;
