import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Social = styled.section`
  ${tw`bg-gray-100 border-t border-b border-indigo-100 m-auto`};
`;

export const SocialButtons = styled.div`
  // ${tw`flex flex-col sm:flex-row items-center justify-center m-4`};
  ${tw`flex flex-wrap items-center justify-center m-4`};
`;

export const SocialItem = styled.div`
  ${tw` p-1`};
`;

export const Form = styled.div`
  ${tw`flex flex-col items-center justify-center mx-auto mt-4`};
`;

export const Input = styled.input`
  ${tw`bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-2 px-4 block w-full appearance-none leading-normal mb-3`};
`;
